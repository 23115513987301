import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import Recordings from "./Recordings";

class RecordingsWrapper extends Component {
  constructor(props) {
    super(props);

    this.state = {
      configPartner: [],
      configLineId:  [],
    };
  }

  componentDidMount() {
    fetch("/api/config")
      .then(res => res.json())
      .then((res) => {
        this.setState({
          configPartner: res.partner,
          configLineId: res.lineId,
        });
        return Promise.resolve();
      })
      .catch((error) => {
        console.log("Error fetch config data " + error);
      });
  };

  render() {
    const { configPartner, configLineId } = this.state;
      return (
        <div>
          {configPartner.length && <Recordings configPartner={configPartner} configLineId={configLineId}/> }          
        </div>
      );    
  }
}

export default RecordingsWrapper;