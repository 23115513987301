import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import axios from "axios";
import RecordingsWrapper from "./RecordingsWrapper";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner , faChevronDown} from '@fortawesome/free-solid-svg-icons'

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      needAuth: true,
    };
  }

  componentDidMount() {
    axios.get("/api/user-info")
      .then((response) => {        
        this.setState({
          needAuth: false,
        });

        return Promise.resolve();
      })
      .catch((error) => {
        //Presmerovanie na login page
        this.setState({needAuth: true});
        window.location.assign(window.location.origin + '/api/login');
      });
  }  

  render() {
    const { needAuth } = this.state;
      return (
        <div>
          {needAuth ? <FontAwesomeIcon icon={faSpinner} className="fa-5x spinner" /> : <RecordingsWrapper/> }
          
        </div>
      );    
  }
}

export default App;