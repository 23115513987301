import React, { useState, useEffect, useMemo, useRef } from "react";
import * as ReactDOM from 'react-dom';
import Pagination from "@mui/material/Pagination";
import { useFilters, useExpanded, useTable } from "react-table";
import axios from "axios";
import { InputGroup, DatePicker, Button } from 'rsuite';
import ReactAudioPlayer from 'react-audio-player';
import "bootstrap/dist/css/bootstrap.min.css";
import "rsuite/dist/rsuite.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import {getRequestParams} from './requestParams'

function Recordings(props) {
  const recordingsRef = useRef();
  const [recordings, setRecordings] = useState([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [pageSize, setPageSize] = useState(25);  
  const [line, setLine] = useState("");
  const [direction, setDirection] = useState("");
  const [ivr, setIvr] = useState("");
  var weekAgo = new Date();
  weekAgo.setDate(weekAgo.getDate()-7);
  const [dateFrom, setDateFrom] = useState(weekAgo);
  const [dateTo, setDateTo] = useState(new Date());
  const [dateTimeRangeValue, setDateTimeRangeValue] = React.useState([
    weekAgo,
    new Date()
  ]);
  const [connectedNumber, setConnectedNumber] = useState();
  const [sort, setSort] = useState();
  const [partner, setPartner] = useState();
  const [customerId, setCustomerId] = useState();
  const [caseReference, setCaseReference] = useState();

  const [lineInput, setLineInput] = useState("");
  const [directionInput, setDirectionInput] = useState("");
  const [ivrInput, setIvrInput] = useState("");
  const [connectedNumberInput, setConnectedNumberInput] = useState();
  const [sortInput, setSortInput] = useState();
  const [partnerInput, setPartnerInput] = useState();
  const [customerIdInput, setCustomerIdInput] = useState();
  const [caseReferenceInput, setCaseReferenceInput] = useState();

  const pageSizes = [25, 50, 100];

  recordingsRef.current = recordings;

  const retrieveRecordings = () => {
    const params = getRequestParams(page, pageSize, partner, line, direction, ivr, connectedNumber, sort, dateTimeRangeValue, customerId, caseReference);
    
    axios.get("/api/recording", {
      params: params,          
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          accept: 'application/json'                      
      }                
    }).then((response) => {
        const totalPages = 1000;
        setRecordings(response.data.content);
        if (Object.keys(response.data.content).length < response.data.pageSize) {
          setCount(response.data.pageSize);
        } else {
          setCount(totalPages);
        }
    }).catch((e) => {
        console.log(e);
    });
  };

  useEffect(retrieveRecordings, [page, pageSize, partner, line, direction, ivr, connectedNumber, sort, dateTimeRangeValue, customerId, caseReference]);
  
  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
    setPage(1);
  };

  const handleDateTimeChange = () => {
    setPartner(partnerInput);
    setLine(lineInput);
    setDirection(directionInput);
    setIvr(ivrInput);
    setConnectedNumber(connectedNumberInput);
    setCustomerId(customerIdInput);
    setCaseReference(caseReferenceInput);
    if (dateFrom != null && dateTo!= null) {
      var dateRange = [dateFrom, dateTo];
      setPage(1);
      setDateTimeRangeValue(dateRange);
    }
  }

  const handleDateFrom = (value) => {
    setDateFrom(value);
  }

  const handleDateTo = (value) => {
    setDateTo(value);
  }

  const formatSeconds = (value) => {
    if (value < 3600) {
      return new Date(value * 1000).toISOString().substring(14, 19);
    } else {
      return new Date(value * 1000).toISOString().substring(11, 16);
    }
  }

  function SelectFilter({
    column: { filterValue, setFilter, dataOptions, id },
  }) {
    const options = getSelectFilterOptions(id);
    const cssClass= "form-select form-select-sm " + id;
  
    // Render a multi-select box
    return (
      <select
        className={cssClass}
        value={filterValue}
        onChange={e => {
          setPage(1);
          selectFilterSwith(id, e.target.value || undefined);
        }}
      >   
        <option value="">Všetky</option>
        {options.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
    )
  }

  function SelectDateFilter({
    column: { filterValue, setFilter, dataOptions, id },
  }) {
    const dateOptions = getDateSelectFilterOptions();
    const cssClass= "form-select form-select-sm " + id;
  
    // Render a multi-select box
    return (
      <select
        className={cssClass}
        value={filterValue}
        onChange={e => {
          setPage(1);
          handleDateSorting(e.target.value || undefined);
        }}
      >   
        <option value="">Bez zoradenia</option>
          {dateOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
      </select>
    )
  }

  function getSelectFilterOptions(rowId) {
    switch(rowId) {
      case 'partner':
        return props.configPartner;
      case 'direction':
        return ["out", "in"];
      case 'lineId':
        return props.configLineId;
      default:
        return "";
    }
  }

  function getDateSelectFilterOptions() {
    return [
      {
        label: "Od najstaršieho",
        value: "a"
      },
      {
        label: "Od najnovšieho",
        value: "d"
      }
    ];
  }

  function selectFilterSwith(rowId, val) {
    switch(rowId) {
      case 'partner':
        setPartnerInput(val);
        return setPartner(val);
      case 'direction':
        setDirectionInput(val);
        return setDirection(val);
      case 'lineId':
        setLineInput(val);
        return setLine(val);
      default:
        return null;
    }
  }

  function handleDateSorting(val) {
    if(val === undefined) {
      setSort(val);
    } else {
      setSort("callStart:" + val);
    }
    
  }

  function SearchFilter({
    column: { filterValue, setFilter, dataOptions, id },
  }) {
    const cssClass= "form-control form-control-sm ";
    return (
      <input
        className={cssClass}
        //value={filterValue || ''}
        type="text"
        onKeyDown={e => {
            if (e.key === 'Enter') {
              setPage(1);
              searchFilterSwith(id, e.target.value || undefined)
            }
          }
        }
        onChange={e => {
            searchFilterSwithInput(id, e.target.value)
          }
        }
        //placeholder={`Search records...`}
      />
    )
  }

  function searchFilterSwith(rowId, val) {
    switch(rowId) {
      case 'ivr':
        return setIvr(val);
      case 'customerId':
        return setCustomerId(val);
      case 'caseReference':
        return setCaseReference(val);
      default:
        return setConnectedNumber(val);
    }
  }

  function searchFilterSwithInput(rowId, val) {
    switch(rowId) {
      case 'ivr':
        return setIvrInput(val);
      case 'customerId':
        return setCustomerIdInput(val);
      case 'caseReference':
        return setCaseReferenceInput(val);
      default:
        return setConnectedNumberInput(val);
    }
  }

  const renderRowSubComponent = React.useCallback(
    ({ row }) => (
      <pre>
        <ReactAudioPlayer
          controls
          >
            <source src={"/api/recording/" + row.original.partner + "/" + row.original.callId} type="audio/mpeg" />
        </ReactAudioPlayer>
      </pre>
    ),
    []
  )
  
  const columns = useMemo(
    () => [
      {
        Header: "Spoločnosť",
        accessor: "partner",
        Filter: SelectFilter,
        filter: 'includes',
      },
      {
        Header: "Linka",
        accessor: "lineId",
        Filter: SelectFilter,
        filter: 'includes',
      },
      {
        Header: "Smer",
        accessor: "direction",
        Filter: SelectFilter,
        filter: 'includes',
      },
      {
        Header: "IVR",
        accessor: "ivr",
        Filter: SearchFilter,
        filter: "include",
      },
      {
        Header: "Začiatok - dátum",
        accessor: date => {
          return new Date(date.callStart).toLocaleString("sk-SK");
        },
        Filter: SelectDateFilter,
        filter: "include",
      },
      {
        Header: "Tel. č.",
        accessor: "connectedNumber",
        Filter: SearchFilter,
        filter: "include",
      },
      {
        Header: "Zákazník č.",
        accessor: "customerId",
        Filter: SearchFilter,
        filter: 'includes'
      },
      {
        Header: "Referencia",
        accessor: "caseReference",
        Filter: SearchFilter,
        filter: 'includes'
      },
      {
        Header: "Dĺžka",
        accessor: row => {
          return formatSeconds(row.callDuration);
        }
      },
      {
        Header: "Čakanie",
        accessor: row => {
          return formatSeconds(row.holdTime);
        }
      },
      {
        Header: "ACW",
        accessor: row => {
          return formatSeconds(row.acw);
        }
      },
      {
        id: 'expander',
        Header: "Play",
        accessor: "callId",
        Cell: ({ row, toggleRowExpandedProps }) =>
            <span
              {...row.getToggleRowExpandedProps({
                style: {
                  paddingLeft: `${row.depth * 2}rem`,
                },
              })}
            >
              {row.isExpanded ? <FontAwesomeIcon icon={faChevronDown} /> : <FontAwesomeIcon icon={faChevronRight} />}  
            </span>
      }      
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    visibleColumns,
    state: { expanded },
  } = useTable({
      columns,
      data: recordings,
      manualFilters: true,
    },
    useFilters,
    useExpanded,
  );

  return (
    <div className="container-fluid mt-3">
      <h1>Nahrávky</h1>

      <div className="field mb-3">
        <p>Dátum</p>
        <InputGroup style={{ width: 580 }}>
          <DatePicker 
            format="dd.MM.yyyy HH:mm:ss" 
            block 
            appearance="subtle" 
            isoWeek={true}
            style={{ width: 230 }} 
            value={dateFrom}
            defaultValue={dateFrom}
            onChange={handleDateFrom}
          />
          <InputGroup.Addon>~</InputGroup.Addon>
          <DatePicker 
            format="dd.MM.yyyy HH:mm:ss" 
            block 
            appearance="subtle" 
            isoWeek={true}
            style={{ width: 230 }}
            value={dateTo}
            defaultValue={dateTo}
            onChange={handleDateTo} 
          />
          <Button appearance="primary" onClick={handleDateTimeChange} block={true}>Vyhľadaj</Button>
        </InputGroup>
      </div>

      <div className="list row">
        <div className="col-md-12 list">

          <table
            className="table table-striped table-bordered"
            {...getTableProps()}
          >
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>
                      {column.render("Header")}
                      <div>{column.Filter ? column.render('Filter') : null}</div>
                    </th>

                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row, i) => {
                prepareRow(row)
                return (
                  <React.Fragment {...row.getRowProps()}>
                    <tr>
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>
                                {cell.render("Cell")}</td>
                        );
                      })}
                    </tr>
                    {row.isExpanded ? (
                    <tr>
                      <td colSpan={visibleColumns.length}>
                        {renderRowSubComponent({ row })}
                      </td>
                    </tr>
                  ) : null}
                  </React.Fragment>
                )
              })}
            </tbody>
          </table>

          <div className="mt-3">
            {"Položky na stránku: "}
            <select onChange={handlePageSizeChange} value={pageSize}>
              {pageSizes.map((size) => (
                <option key={size} value={size}>
                  {size}
                </option>
              ))}
            </select>

            <Pagination
              color="primary"
              className="my-3"
              count={count==1000 ? page+1 : page}
              page={page}
              siblingCount={1}
              boundaryCount={1}
              variant="outlined"
              onChange={handlePageChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Recordings;