export const getRequestParams = (page, pageSize, partner, line, direction, ivr, connectedNumber, sort, dateTimeRangeValue, customerId, caseReference) => {
    let params = {};

    if(dateTimeRangeValue){
      params["od"] = dateTimeRangeValue[0];
      params["do"] = dateTimeRangeValue[1];
    }

    if (partner) {
      params["spolocnost"] = partner;
    }

    if (page) {
      params["page"] = page;
    }

    if (pageSize) {
      params["size"] = pageSize;
    }   

    if (line) {
      params["lineId"] = line;
    }

    if (direction) {
      params["direction"] = direction;
    }

    if (ivr) {
      params["ivr"] = ivr;
    }

    if (connectedNumber) {
      params["telefonCislo"] = connectedNumber;
    }

    if (sort) {
      params["sort"] = sort;
    }

    if (customerId) {
      params["opCislo"] = customerId;
    }
    
    if (caseReference) {
      params["referencia"] = caseReference;
    }      

    return params;
  };